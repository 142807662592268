import { Transfer } from "src/app/domain/transfers/models/transfer";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TransfersService } from "../services/transfers.service.service";
import { ActivatedRoute, Router } from "@angular/router";
import { dateTimeFormatValidator } from "src/app/shared/validators/date.validator";
import { EntityPicklistType, PicklistType } from "src/app/shared/enums/picklist-types";
import * as moment from "moment";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { PopupType } from "src/app/shared/enums/popup-types";
import { TranslateService } from "@ngx-translate/core";
import { UtilService } from "src/app/shared/services/util.service";

@Component({
  selector: "app-transfer-edit",
  templateUrl: "./transfer.edit.component.html",
})
export class TransferEditComponent implements OnInit {
  form!: FormGroup;
  transferId!: any;
  PicklistType = PicklistType;
  EntityPicklistType = EntityPicklistType;

  constructor(
    private fb: FormBuilder,
    private transferService: TransfersService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    public utilService: UtilService,
    private router: Router
  ) {
    this.form?.get("twoWay")?.valueChanges.subscribe((value) => {
      this.form?.get("twoWay")?.setValue(!value);
    });
  }

  initForm(transfers: Transfer | undefined) {
    const { name, departureDate, arrivalDate, departure, destination, vehicleOption, capacity, twoWay } = transfers || { Transfer: {} };
    this.form = this.fb.group({
      name: [name, Validators.required],
      departureDate: [this.formattedDate(departureDate), { validators: [Validators.required, dateTimeFormatValidator()], updateOn: "blur" }],
      arrivalDate: [this.formattedDate(arrivalDate), { validators: [Validators.required, dateTimeFormatValidator()], updateOn: "blur" }],
      departure: [departure, Validators.required],
      destination: [destination, Validators.required],
      vehicle: [vehicleOption, Validators.required],
      capacity: [capacity, Validators.required],
      twoWay: [twoWay],
    });
  }

  dateTimeMask = [/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, ":", /\d/, /\d/];
  dateMask = [/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/];

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.transferId = params.get("id");
      this.getTransfer(this.transferId);
    });
  }

  get twoWay() {
    return this.form?.get("twoWay")?.value;
  }

  getTransfer(id: any) {
    this.transferService.getTransferData(id).subscribe(
      (res) => {
        this.initForm(res);
      },
      (error) => {
        showMessage(PopupType.Danger, error.toString());
      }
    );
  }

  submitForm() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      showMessage(PopupType.Warning, this.translateService.instant("transfer.fill_all_fields"));
      return;
    }
    this.updateTransfer();
  }

  fetchTransfer() {
    const { name, departureDate, arrivalDate, departure, vehicle, destination, capacity, twoWay } = this.form.getRawValue();
    const data = {
      name,
      departureDate: moment(departureDate, "DD.MM.YYYY HH:mm"),
      arrivalDate: moment(arrivalDate, "DD.MM.YYYY HH:mm"),
      departureId: departure?.id,
      destinationId: destination?.id,
      vehicleOptionId: vehicle?.id,
      capacity: capacity,
      twoWay,
    };

    return data;
  }

  updateTransfer() {
    const data = this.fetchTransfer();
    this.transferService.updateTransfer(this.transferId, data).subscribe(
      (res) => {
        showMessage(PopupType.Success, this.translateService.instant("transfer.updated_transfer_successfully"));
        this.router.navigate(["/dashboard/transfers"]);
      },
      (error) => {
        showMessage(PopupType.Danger, error.toString());
      }
    );
  }

  toggleTwoWay() {
    const currentValue = this.form?.get("twoWay")?.value;
    this.form?.get("twoWay")?.setValue(!currentValue);
  }

  formattedDate(date: any) {
    const parsedDateTime = moment(date, "YYYY-MM-DD HH:mm");
    const formattedDateTime = parsedDateTime.format("DD.MM.YYYY HH:mm");
    return formattedDateTime;
  }
}
