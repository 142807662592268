import { Injectable, Injector, ComponentFactoryResolver, ApplicationRef } from "@angular/core";
import { ConfirmationDialogComponent } from "../components/confirmation-dialog/confirmation-dialog.component";
import { TranslateService } from "@ngx-translate/core";

interface ConfirmationDialogOptions {
	title?: string;
	message?: string;
	confirmButtonText?: string;
	cancelButtonText?: string;
}

@Injectable({
	providedIn: "root",
})
export class ModalService {
	constructor(private injector: Injector, private resolver: ComponentFactoryResolver, private appRef: ApplicationRef, private translateService: TranslateService) { }

	confirm(options: ConfirmationDialogOptions = {}): Promise<boolean> {
		return new Promise<boolean>((resolve) => {
			const componentFactory = this.resolver.resolveComponentFactory(ConfirmationDialogComponent);
			const componentRef = componentFactory.create(this.injector);

			// Set the component properties
			componentRef.instance.title = options.title || this.translateService.instant('modal.confirmation');
			componentRef.instance.message = options.message || this.translateService.instant('modal.are_you_sure_you_want_to_perform_this_action');
			componentRef.instance.confirmButtonText = options.confirmButtonText || this.translateService.instant('modal.confirm');
			componentRef.instance.cancelButtonText = options.cancelButtonText || this.translateService.instant('modal.cancel');

			componentRef.instance.confirmed.subscribe((confirmed: boolean) => {
				resolve(confirmed);
				this.appRef.detachView(componentRef.hostView);
				componentRef.destroy();
			});

			this.appRef.attachView(componentRef.hostView);

			const domElem = (componentRef.hostView as any).rootNodes[0] as HTMLElement;
			document.body.appendChild(domElem);
		});
	}
}
