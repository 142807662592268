<form class="space-y-5 p-5" [formGroup]="form" (ngSubmit)="submitForm()" novalidate>
  <div class="flex space-x-4">
    <div>
      <label class="flex cursor-pointer items-center">
        <input type="radio" name="withAvans" [value]="0" (change)="setWithAvans(0)" class="form-radio" [checked]="!withAvans" />
        <span class="text-white-dark">{{ "sale_create.payment_in_full" | translate }}</span>
      </label>
    </div>
    <div>
      <label class="flex cursor-pointer items-center">
        <input type="radio" name="withAvans" [value]="1" (change)="setWithAvans(1)" class="form-radio" [checked]="withAvans" />
        <span class="text-white-dark">{{ "sale_create.payment_in_instalments" | translate }}</span>
      </label>
    </div>
  </div>
  <div class="grid grid-cols-1 gap-5 md:grid-cols-2">
    <div [ngClass]="form.controls['neto'].touched ? (form.controls['neto'].errors ? 'has-error' : 'has-success') : ''">
      <label for="customNeto">{{ "sale_create.neto" | translate }}</label>
      <div class="flex">
        <div
          class="flex items-center justify-center border border-[#e0e6ed] bg-[#eee] px-3 font-semibold dark:border-[#17263c] dark:bg-[#1b2e4b] ltr:rounded-l-md ltr:border-r-0 rtl:rounded-r-md rtl:border-l-0"
        >
          €
        </div>
        <input
          [type]="netoFieldType"
          pattern="[0-9]*"
          appOnlyNumber
          autocomplete="off"
          inputmode="numeric"
          id="customNeto"
          placeholder="{{ 'sale_create.enter_neto' | translate }}"
          formControlName="neto"
          class="form-input flex-1 ltr:rounded-l-none ltr:rounded-r-none ltr:border-r-0 rtl:rounded-r-none"
        />
        <div
          class="flex cursor-pointer items-center justify-center border border-secondary bg-primary px-3 font-semibold text-white ltr:rounded-r-md ltr:border-l-0 rtl:rounded-l-md rtl:border-r-0"
          (mousedown)="reveal()"
          (mouseup)="hide()"
        >
          <icon-info-circle></icon-info-circle>
        </div>
      </div>
      <ng-container *ngIf="form.controls['neto'].touched && !form.controls['neto'].errors">
        <p class="mt-1 text-[#1abc9c]">{{ "sale_create.looks_good" | translate }}</p>
      </ng-container>
      <ng-container *ngIf="form.controls['neto'].touched && form.controls['neto'].errors">
        <p class="mt-1 text-danger">{{ "sale_create.please_fill_neto" | translate }}</p>
      </ng-container>
    </div>
    <div [ngClass]="form.controls['bruto'].touched ? (form.controls['bruto'].errors ? 'has-error' : 'has-success') : ''">
      <label for="customBruto">{{ "sale_create.bruto" | translate }}</label>
      <div class="flex">
        <div
          class="flex items-center justify-center border border-[#e0e6ed] bg-[#eee] px-3 font-semibold dark:border-[#17263c] dark:bg-[#1b2e4b] ltr:rounded-l-md ltr:border-r-0 rtl:rounded-r-md rtl:border-l-0"
        >
          €
        </div>
        <input
          id="customBruto"
          type="number"
          placeholder="{{ 'sale_create.enter_bruto' | translate }}"
          formControlName="bruto"
          class="form-input flex-1 ltr:rounded-l-none ltr:rounded-l-none rtl:rounded-r-none rtl:rounded-r-none rtl:border-l-0"
        />
      </div>
      <ng-container *ngIf="form.controls['bruto'].touched && !form.controls['bruto'].errors">
        <p class="mt-1 text-[#1abc9c]">{{ "sale_create.looks_good" | translate }}</p>
      </ng-container>
      <ng-container *ngIf="form.controls['bruto'].touched && form.controls['bruto'].errors && !form.controls['bruto'].errors['brutoLessThanNeto']">
        <p class="mt-1 text-danger">{{ "sale_create.please_fill_bruto" | translate }}</p>
      </ng-container>
      <ng-container *ngIf="form.controls['bruto'].touched && form.controls['bruto'].errors && form.controls['bruto'].errors['brutoLessThanNeto']">
        <p class="mt-1 text-danger">{{ "sale_create.bruto_less_then_neto" | translate }}</p>
      </ng-container>
    </div>
  </div>
  <div class="grid grid-cols-1 gap-5 md:grid-cols-2" *ngIf="withAvans">
    <div [ngClass]="form.controls['avans'].touched ? (form.controls['avans'].errors ? 'has-error' : 'has-success') : ''">
      <label for="customAvans">{{ "sale_create.avans_payment" | translate }}</label>
      <div class="flex">
        <div
          class="flex items-center justify-center border border-[#e0e6ed] bg-[#eee] px-3 font-semibold dark:border-[#17263c] dark:bg-[#1b2e4b] ltr:rounded-l-md ltr:border-r-0 rtl:rounded-r-md rtl:border-l-0"
        >
          €
        </div>
        <input
          id="customAvans"
          type="number"
          [max]="form.controls['bruto'].value"
          placeholder="{{ 'sale_create.please_fill_payment_in_instalments' | translate }}"
          formControlName="avans"
          class="form-input flex-1 ltr:rounded-l-none rtl:rounded-r-none"
        />
      </div>
      <ng-container *ngIf="form.controls['avans'].touched && !form.controls['avans'].errors">
        <p class="mt-1 text-[#1abc9c]">{{ "sale_create.looks_good" | translate }}</p>
      </ng-container>
      <ng-container *ngIf="form.controls['avans'].touched && form.controls['avans'].errors">
        <p class="mt-1 text-danger">{{ "sale_create.please_provide_valid_amount" | translate }}</p>
      </ng-container>
    </div>

    <div [ngClass]="form.controls['dueDate'].touched ? (form.controls['dueDate'].errors ? 'has-error' : 'has-success') : ''">
      <label for="customDueDate">{{ "sale_create.due_date" | translate }}</label>
      <input id="customDueDate" type="text" placeholder="{{ 'sale_create.enter_due_date' | translate }}" class="form-input" placeholder="__.__.____" [textMask]="{ mask: dateMask }" formControlName="dueDate" />
      <ng-container *ngIf="form.controls['dueDate'].touched && !form.controls['dueDate'].errors">
        <p class="mt-1 text-[#1abc9c]">{{ "sale_create.looks_good" | translate }}</p>
      </ng-container>
      <ng-container *ngIf="form.controls['dueDate'].touched && form.controls['dueDate'].errors">
        <p class="mt-1 text-danger">{{ "sale_create.please_provide_due_date" | translate }}</p>
      </ng-container>
    </div>
  </div>
  <div class="grid grid-cols-1 gap-5 md:grid-cols-2">
    <div [ngClass]="form.controls['paymentType'].touched ? (form.controls['paymentType'].errors ? 'has-error' : 'has-success') : ''">
      <label for="customType">{{ "sale_create.payment_type" | translate }}</label>
      <div>
        <app-select-remote id="customType" [type]="PicklistType.PaymentType" [selectControl]="form" labelForId="customType" formControlName="paymentType" [setDefaultFirstOption]="true"> </app-select-remote>
      </div>
      <ng-container *ngIf="form.controls['paymentType'].touched && !form.controls['paymentType'].errors">
        <p class="mt-1 text-[#1abc9c]">{{ "sale_create.looks_good" | translate }}</p>
      </ng-container>
      <ng-container *ngIf="form.controls['paymentType'].touched && form.controls['paymentType'].errors">
        <p class="mt-1 text-danger">{{ "sale_create.please_select_payment_type" | translate }}</p>
      </ng-container>
    </div>
  </div>
</form>
