<div class="grid w-full grid-cols-2 gap-2 sm:w-auto sm:grid-cols-4">
  <label class="btn flex cursor-pointer items-center justify-center" [ngClass]="{ 'bg-secondary text-white': filterType === 'None', 'btn-outline-secondary': filterType !== 'None' }">
    <input type="radio" name="paymentStatus" [(ngModel)]="filterType" value="None" class="hidden" (change)="onSelectionChange('None')" />
    <icon-user class="h-5 w-5 ltr:mr-2 rtl:ml-2"></icon-user>
    {{ "global_table_filters.total" | translate }}
    <span class="badge my-0 bg-white-light text-black ltr:ml-4 rtl:mr-4">{{ total }}</span>
  </label>

  <label class="btn flex cursor-pointer items-center justify-center" [ngClass]="{ 'bg-primary text-white': filterType === 'WithoutDebt', 'btn-outline-primary': filterType !== 'WithoutDebt' }">
    <input type="radio" name="paymentStatus" [(ngModel)]="filterType" value="WithoutDebt" class="hidden" (change)="onSelectionChange('WithoutDebt')" />
    {{ "global_table_filters.paid" | translate }}
    <span class="badge my-0 bg-white-light text-black ltr:ml-4 rtl:mr-4">{{ withoutDept }}</span>
  </label>

  <label class="btn flex cursor-pointer items-center justify-center" [ngClass]="{ 'bg-danger text-white': filterType === 'Debt', 'btn-outline-danger': filterType !== 'Debt' }">
    <input type="radio" name="paymentStatus" [(ngModel)]="filterType" value="Debt" class="hidden" (change)="onSelectionChange('Debt')" />
    {{ "global_table_filters.not_paid" | translate }}
    <span class="badge my-0 bg-white-light text-black ltr:ml-4 rtl:mr-4">{{ withDept }}</span>
  </label>
  <label class="btn flex cursor-pointer items-center justify-center" [ngClass]="{ 'bg-info text-white': filterType === '', 'btn-outline-info': filterType !== '' }">
    <input type="radio" name="paymentStatus" [(ngModel)]="filterType" value="" class="hidden" (change)="onSelectionChange('')" />
    {{ "global_table_filters.started" | translate }}
    <span class="badge my-0 bg-white-light text-black ltr:ml-4 rtl:mr-4"></span>
  </label>
</div>
