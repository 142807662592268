import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StatisticsYear, } from 'src/app/domain/statisticsmoney/models/statisticsyear'; // Adjust path as per your project structure

@Component({
	selector: 'app-statistics-chart',
	templateUrl: './statistics-chart.component.html'
})
export class StatisticsChartComponent implements OnInit, OnChanges {
	@Input() simpleColumnStacked: StatisticsYear | undefined;
	simpleColumnStackedSeries: any;

	availableYears: number[] = [];
	selectedYear: number | undefined;

	constructor(private translate: TranslateService) { }

	ngOnInit(): void {
		this.updateChart();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['simpleColumnStacked'] && changes['simpleColumnStacked'].currentValue) {
			this.updateChart();
		}
	}

	updateChart() {
		if (this.simpleColumnStacked && this.simpleColumnStacked.yearlyServiceStats) {
			const yearlyStats = this.simpleColumnStacked.yearlyServiceStats;
			const years = yearlyStats.map(item => item.year);
			const ticketCounts = yearlyStats.map(item => item.ticketCount);
			const travelCounts = yearlyStats.map(item => item.travelCount);
			const groupTravelCounts = yearlyStats.map(item => item.groupTravelCount);
			const transferCounts = yearlyStats.map(item => item.transferCount);

			this.availableYears = Array.from(new Set(years));
			this.selectedYear = this.availableYears[this.availableYears.length - 1];

			const isDark = true;
			const isRtl = false;

			this.simpleColumnStackedSeries = {
				series: [
					{ name: this.translate.instant('statisticsChart.ticketCount'), data: ticketCounts },
					{ name: this.translate.instant('statisticsChart.travelCount'), data: travelCounts },
					{ name: this.translate.instant('statisticsChart.groupTravelCount'), data: groupTravelCounts },
					{ name: this.translate.instant('statisticsChart.transferCount'), data: transferCounts }
				],
				chart: {
					height: 350,
					type: "bar",
					zoom: { enabled: false },
					toolbar: { show: false }
				},
				dataLabels: { enabled: false },
				stroke: { show: true, width: 2, colors: ["transparent"] },
				colors: ["#46b196", "#af4133", "#d4483b", "#fbcb3e"],
				xaxis: {
					categories: years,
					axisBorder: { color: isDark ? "#191e3a" : "#e0e6ed" }
				},
				yaxis: {
					opposite: isRtl,
					labels: { offsetX: isRtl ? -10 : 0 }
				},
				grid: { borderColor: isDark ? "#ADD8E6" : "silver" },
				plotOptions: {
					bar: {
						horizontal: false,
						columnWidth: "55%",
						endingShape: "rounded"
					}
				},
				tooltip: {
					theme: isDark ? "dark" : "light",
					y: { formatter: (val: any) => val }
				}
			};
		}
	}
}
