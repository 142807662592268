import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MonthlyDebt, MonthlyDebtStatistics } from "src/app/domain/statisticsmoney/models/monthlydebt";

@Component({
	selector: "app-montly-debt-chart",
	templateUrl: "./montly-debt-chart.component.html",
})
export class MontlyDebtChartComponent implements OnInit, OnChanges {
	@Input() montlyearningDebt: MonthlyDebtStatistics | undefined;
	simpleColumnStackedSeries: any;

	constructor(private translate: TranslateService) { }

	ngOnInit(): void {
		this.updateChart();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes["montlyearningDebt"] && changes["montlyearningDebt"].currentValue) {
			this.updateChart();
		}
	}

	getMonthName(monthNumber: number): string {
		return this.translate.instant(`months.${monthNumber}`);
	}

	updateChart() {
		if (this.montlyearningDebt && this.montlyearningDebt.monthlyServiceDebtStats) {
			const monthlyStats = this.montlyearningDebt.monthlyServiceDebtStats;
			const months = monthlyStats.map((item) => this.getMonthName(item.month));
			const ticketDebt = monthlyStats.map((item) => item.ticketDebt);
			const travelDebt = monthlyStats.map((item) => item.travelDebt);
			const groupTravelDebt = monthlyStats.map((item) => item.groupTravelDebt);
			const transferDebt = monthlyStats.map((item) => item.transferDebt);
			const isDark = true;
			const isRtl = false;

			this.simpleColumnStackedSeries = {
				series: [
					{ name: this.translate.instant("debt.ticket_debt"), data: ticketDebt },
					{ name: this.translate.instant("debt.travel_debt"), data: travelDebt },
					{ name: this.translate.instant("debt.group_travel_debt"), data: groupTravelDebt },
					{ name: this.translate.instant("debt.transfer_debt"), data: transferDebt },
				],
				chart: {
					height: 350,
					type: "bar",
					zoom: { enabled: false },
					toolbar: { show: false },
				},
				dataLabels: { enabled: false },
				stroke: { show: true, width: 2, colors: ["transparent"] },
				colors: ["#46b196", "#af4133", "#d4483b", "#fbcb3e"],
				xaxis: {
					categories: months,
					axisBorder: { color: isDark ? "#191e3a" : "#e0e6ed" },
				},
				yaxis: {
					opposite: isRtl,
					labels: { offsetX: isRtl ? -10 : 0 },
				},
				grid: { borderColor: isDark ? "#ADD8E6" : "silver" },
				plotOptions: {
					bar: {
						horizontal: false,
						columnWidth: "55%",
						endingShape: "rounded",
					},
				},
				tooltip: {
					theme: isDark ? "dark" : "light",
					y: { formatter: (val: any) => val },
				},
			};
		}
	}
}
