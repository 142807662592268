import { Component, Input, ViewChild, ViewContainerRef } from "@angular/core";

@Component({
	moduleId: module.id,
	selector: "icon-info-circle",
	template: `
    <ng-template #template>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 20 20"
        width="20"
        height="20"
        style="enable-background:new 0 0 20 20;"
        xml:space="preserve"
        [ngClass]="class"
      >
        <style type="text/css">
          .st0 {
            fill: currentColor;
          }
          .st1 {
            fill: #FFFFFF;
          }
          .st2 {
            font-family: 'CroissantOne-Regular';
          }
          .st3 {
            font-size: 9.1258px;
          }
        </style>
        <g>
          <circle class="st0" cx="10" cy="10" r="9.1" />
          <g>
            <text transform="matrix(1 0 0 1 8.46 13.251)" class="st1 st2 st3">i</text>
          </g>
        </g>
      </svg>
    </ng-template>
  `,
})
export class IconInfoCircleComponent {
	@Input() class: any = "";
	@ViewChild("template", { static: true }) template: any;

	constructor(private viewContainerRef: ViewContainerRef) { }

	ngOnInit() {
		this.viewContainerRef.createEmbeddedView(this.template);
		this.viewContainerRef.element.nativeElement.remove();
	}
}