import { Store } from "@ngrx/store";
import { colDef } from "@bhplugin/ng-datatable";
import { ActivatedRoute } from "@angular/router";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { AppState } from "src/app/store/index.selector";
import { selectFromTo } from "src/app/store/datepicker/datetime.selector";
import { animate, style, transition, trigger } from "@angular/animations";
import { TranslateService } from "@ngx-translate/core";
import { TransfersService } from "../../transfers/services/transfers.service.service";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { BaseTableComponent, DatatableColumn } from "src/app/shared/components/base-table/base-table.component";
import { TableService } from "src/app/shared/services/table.service";
import { Transfer } from "../models/transfer";
import { TicketsService } from "../../tickets/services/tickets.service";
import { MessagePortalComponent } from "src/app/portal/portal";
import { PositioningService } from "src/app/shared/services/positioning.service";
import { ModalService } from "src/app/shared/services/modal.service";
import { ModalComponent } from "angular-custom-modal";

interface Passenger {
  reservationNumber: string | null;
  ticketId: number;
  id: number;
  name: string;
  surname: string;
}

@Component({
  moduleId: module.id,
  templateUrl: "./transfers.table.component.html",
  animations: [
    trigger("toggleAnimation", [
      transition(":enter", [style({ opacity: 0, transform: "scale(0.95)" }), animate("100ms ease-out", style({ opacity: 1, transform: "scale(1)" }))]),
      transition(":leave", [animate("75ms", style({ opacity: 0, transform: "scale(0.95)" }))]),
    ]),
  ],
})
export class TransfersTableComponent extends BaseTableComponent<Transfer> {
  public type = "flight";
  public transfers!: Transfer[];
  public filteredItems: any[] = [];
	transferToClone:any;

  constructor(
    private modalService: ModalService,
    public override store: Store<AppState>,
    private ticketsService: TicketsService,
    private route: ActivatedRoute,
    public override translate: TranslateService,
    private transferService: TransfersService,
    public override tableService: TableService,
    private positioningService: PositioningService
  ) {
    super(store, translate, selectFromTo, tableService, `transfers/paginated`);
  }
  @ViewChild("dropdownPlaceholder") dropdownPlaceholder!: ElementRef;
  @ViewChild(MessagePortalComponent) messagePortalComponent!: MessagePortalComponent;
	@ViewChild("cloneConfirmationModal")
  cloneConfirmationModal!: ModalComponent;
  setPositionOnClick() {
    setTimeout(() => {
      this.positioningService.setPosition(this.dropdownPlaceholder, this.messagePortalComponent);
    }, 10);
  }

  override ngOnInit() {
    super.ngOnInit();
    this.getColumns();
    // this.route.params.subscribe((params) => {
    // 	this.apiUrl = `transfers/paginated?type=${params["type"]}`;
    // 	super.ngOnInit();
    // 	this.getTransfers();
    // });
  }
  getColumns(): void {
    this.cols = [
      { field: "actions", title: this.translate.instant("transfer.actions"), hide: false, sort: false } as DatatableColumn,
      { field: "id", title: this.translate.instant("travels.id"), hide: true } as DatatableColumn,
      { field: "name", title: this.translate.instant("transfer.name"), hide: false, sort: false } as DatatableColumn,
      { field: "capacity", title: this.translate.instant("transfer.capacity"), hide: false } as DatatableColumn,
      { field: "vehicleOptionId", title: this.translate.instant("transfer.vehicle"), hide: false } as DatatableColumn,
      { field: "departureDate", title: this.translate.instant("transfer.departure_date"), hide: false } as DatatableColumn,
      { field: "arrivalDate", title: this.translate.instant("transfer.arrival_date"), hide: false } as DatatableColumn,
      { field: "departureId", title: this.translate.instant("transfer.departure"), hide: false } as DatatableColumn,
      { field: "destinationId", title: this.translate.instant("transfer.destination"), hide: false } as DatatableColumn,
      { field: "details", title: this.translate.instant("transfer.details"), hide: false } as DatatableColumn,
    ];
  }
  public exportCols = [
    { field: "id", title: this.translate.instant("transfers-export.id"), isUnique: true, hide: true } as DatatableColumn,
    { field: "numberOfPassengers", title: this.translate.instant("transfers-export.numberOfPassengers"), hide: false, sort: false } as DatatableColumn,
    { field: "departure", title: this.translate.instant("transfers-export.departure"), hide: false } as DatatableColumn,
    { field: "destination", title: this.translate.instant("transfers-export.destination"), hide: false } as DatatableColumn,
    { field: "returnDate", title: this.translate.instant("transfers-export.returnDate"), hide: false } as DatatableColumn,
    { field: "bruto", title: this.translate.instant("transfers-export.bruto"), hide: false } as DatatableColumn,
    { field: "neto", title: this.translate.instant("transfers-export.neto"), hide: true, sort: false } as DatatableColumn,
    { field: "status", title: this.translate.instant("transfers-export.status"), hide: true, sort: false } as DatatableColumn,
    { field: "saleDate", title: this.translate.instant("transfers-export.saleDate"), hide: true, sort: false } as DatatableColumn,
    { field: "user", title: this.translate.instant("transfers-export.user"), hide: true, sort: false } as DatatableColumn,
  ];

  getReservationNumber(pass: Passenger[]) {
    if (pass) {
      const arr = pass.map((passenger) => passenger.reservationNumber);
      return arr.join(", ");
    }
    return "";
  }

  getTransfers() {
    this.transferService.getTransfers().subscribe(
      (res) => {
        this.transfers = res;
      },
      (err) => showMessage(PopupType.Danger, err.toString())
    );
  }
	async createDuplicate(value: Transfer) {
    const title = this.translate.instant("clone.title");
    const message = this.translate.instant("clone.message");

    const confirmed = await this.modalService.confirm({
      title: title,
      message: message,
      confirmButtonText: this.translate.instant("common.confirm"),
      cancelButtonText: this.translate.instant("common.cancel"),
    });

    if (confirmed) {
      this.transferToClone = value.id;
      this.cloneTransfer();
    }
  }

  onPortalAttached() {
    setTimeout(() => {
      this.positioningService.setPosition(this.dropdownPlaceholder, this.messagePortalComponent);
    }, 0);
  }

  cloneTransfer() {
    this.transferService.createTransferClone(this.transferToClone).subscribe((r) => {
			this.fetchItems();
      showMessage(PopupType.Success, this.translate.instant("popup.object_cloned_successfully"));
      this.cloneConfirmationModal.close();
		});
  }
  async deleteTransfer(id: number) {
    const confirmed = await this.modalService.confirm();
    if (confirmed) {
      this.transferService.deleteTransfer(id).subscribe(
        (res) => {
          this.ngOnInit();
        },
        (err) => showMessage(PopupType.Danger, err.toString())
      );
		}
	}
}
